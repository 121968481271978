import React, { createContext, useContext, ReactNode, useCallback } from 'react';
import type { CategoryOptionShape } from '@hipagesgroup/get-quotes';
import { useGetQuotesWizard } from '@hipagesgroup/get-quotes';

// Define the type for the context value
interface GetQuotesPackageWrapperContextType {
  openGetQuotesWithDefaultOptions: () => void;
  openGetQuotesWithPrefilledCategory: (location?: string) => void;
}

// Create the context with an initial undefined value
const GetQuotesPackageWrapperContext = createContext<
  GetQuotesPackageWrapperContextType | undefined
>(undefined);

// Create the provider component
interface GetQuotesPackageWrapperProviderProps {
  children: ReactNode; // React children
  prefilledCategory: CategoryOptionShape | null;
}

export const GetQuotesPackageWrapperProvider: React.FC<GetQuotesPackageWrapperProviderProps> = ({
  children,
  prefilledCategory,
}) => {
  const { openWizard } = useGetQuotesWizard();

  const openGetQuotesWithDefaultOptions = useCallback(openWizard, [openWizard]);

  const openGetQuotesWithPrefilledCategory = useCallback(
    (location?: string) => {
      openWizard({
        category: prefilledCategory ?? undefined,
        location,
        forceUserToReviewCategory: true,
      });
    },
    [prefilledCategory, openWizard]
  );

  return (
    <GetQuotesPackageWrapperContext.Provider
      value={{ openGetQuotesWithDefaultOptions, openGetQuotesWithPrefilledCategory }}
    >
      {children}
    </GetQuotesPackageWrapperContext.Provider>
  );
};

export const useGetQuotesPackageWrapperContext = (): GetQuotesPackageWrapperContextType => {
  const context = useContext(GetQuotesPackageWrapperContext);
  if (!context) {
    throw new Error(
      'useGetQuotesPackageWrapperContext must be used within a GetQuotesPackageWrapperProvider'
    );
  }
  return context;
};
